import type { PricedProduct } from "@medusajs/medusa/dist/types/pricing";

export const useShopStore = defineStore("shopStore", {
  state: () => ({
    limit: 12,
    offset: 1,
    count: 12,
    selectedCategories: [] as string[],
    products: [] as PricedProduct[],
    filterMenuOpen: false,
    pending: false

  }),
  getters: {
    reqQuery: (state) => {
      const { currencyCode } = storeToRefs(useRegionStore())
      return {
        limit: state.limit,
        offset: (state.offset - 1) * state.limit,
        category_id: state.selectedCategories,
        include_category_children: true,
        currency_code: currencyCode.value
      }
    }
  },
  actions: {
    async getProducts() {
      if (import.meta.server) {
        return
      }
      this.pending = true
      const { products: _prod, count: _count } =
        await useCybandyClient().products.list(this.reqQuery);
      this.products.push(..._prod)
      this.count = _count
      this.pending = false
    },

    async loadMore() {
      this.offset++
      await this.getProducts()
    },

    async resetQuery() {
      this.limit = 12
      this.offset = 1
      this.products = []
      this.count = 12
      this.filterMenuOpen = false

    }
  }
})
